import axios from 'axios'
import React from 'react'
import { CONFIG } from '../../config'

export const DetailsApp = () => {

    return (
        <div className='container'>
            <br />
            <h4 className='fw-bold'>Detalles del desafío</h4>
            <hr />
        </div>
    )
}
